import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useGetTransactions } from "../hooks";
import DataTable from "examples/Tables/DataTable";
import { useDataTable } from "hooks/useDataTable";
import { fDateTimeSuffix } from "utils/formatTime";
import { useForm } from "react-hook-form";
import FormProvider from "components/FormProviders";
import StatusChip from "components/StatusChip";
import { sanitizeRequest } from "../schemas/filters";
import Filters from "./Filters";
import SoftBox from "components/SoftBox";
import { set } from "utils/localStorage";
import { statusColorEnum } from "constants/reconciliation";

const Transactions = () => {
  const { id } = useParams();
  const sortColumns = ["createdAt", "status", "reconTxStatus", "currency", "paymentAmount"];
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const { onSortChange, sortDirection, setSortDirection } = useDataTable();

  const { mutate, isLoading, data } = useGetTransactions();

  // forms
  const methods = useForm({ defaultValues: {} });
  const { handleSubmit, getValues } = methods;

  // functions
  const getRows = () => {
    return _.map(_.get(data, "docs", []), (item) => ({
      id: item._id,
      sheetName: item.sheetName,
      idField: item.idField,
      transactionId: item.transactionId,
      status: <>{item.status ? <StatusChip label={item.status} /> : ""}</>,
      internalStatus: <>{item.status ? <StatusChip label={item.internalStatus} /> : ""}</>,
      paymentAmount: _.get(item, "paymentAmount", ""),
      internalPaymentAmount: _.get(item, "internalPaymentAmount", ""),
      currency: _.get(item, "currency", ""),
      internalCurrency: _.get(item, "internalCurrency", ""),
      rate: _.get(item, "rate", ""),
      reconTxStatus: (
        <>
          {item.reconTxStatus ? (
            <StatusChip label={item.reconTxStatus} colorEnum={statusColorEnum} />
          ) : (
            ""
          )}
        </>
      ),
      createdAt: fDateTimeSuffix(item.createdAt),
    }));
  };

  // create a function that handles the page change
  const handlePageChange = (page) => {
    setCurrentPage(page + 1);
    const payload = getValues();
    if (payload.sortDirection) setSortDirection(payload.sortDirection);
    payload.page = page + 1;
    mutate(sanitizeRequest({ ...payload, id, limit: pageSize }));
  };
  // create a function that handles the page size change
  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
    const payload = getValues();
    payload.page = 1;
    payload.limit = pageSize;
    mutate(sanitizeRequest({ ...payload, id }));
  };
  const handleSortChange = (column) => {
    const LC_PAYLOAD = "reconciliationDetailPayload";
    set(LC_PAYLOAD, sanitizeRequest({ ...getValues(), id, limit: pageSize, page: currentPage }));
    onSortChange({
      column,
      sortColumns: sortColumns,
      mutate,
      lcPayload: LC_PAYLOAD,
      setCurrentPage,
      sanitizeRequest,
    });
  };
  const onSubmit = (values) => {
    const payload = {
      ...values,
      limit: pageSize,
      page: 1,
      sortBy: "createdAt",
      sortDirection: sortDirection,
    };
    setCurrentPage(1);
    mutate(sanitizeRequest({ ...payload, id }));
  };

  const handleCellClick = (row) => {
    if (row.original.transactionId) {
      window.open(
        `${window.location.origin}/transactions/${row.original.transactionId}?original=${id}&reconciliation=true`
      );
    }
  };

  useEffect(() => {
    mutate({ id, limit: pageSize, page: 1 });
  }, [id]);

  return (
    <SoftBox>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Filters />
      </FormProvider>
      <SoftBox sx={{ mt: 2 }}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id", hide: true },
              { Header: "Sheet", accessor: "sheetName" },
              { Header: "ID from PSP", accessor: "idField" },
              { Header: "Matched Transaction ID", accessor: "transactionId" },
              { Header: "Currency", accessor: "currency" },
              { Header: "Payment Amount", accessor: "paymentAmount" },
              { Header: "Internal Currency", accessor: "internalCurrency" },
              { Header: "Internal Payment Amount", accessor: "internalPaymentAmount" },
              { Header: "Rate", accessor: "rate" },
              { Header: "Status", accessor: "status" },
              { Header: "Internal Status", accessor: "internalStatus" },
              { Header: "Reconciliation Status", accessor: "reconTxStatus" },
              { Header: "Created time", accessor: "createdAt" },
            ],
            rows: getRows(),
          }}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          pageCount={_.get(data, "totalPages", 0)}
          manualPagination={true}
          rowCount={_.get(data, "totalDocs", 0)}
          isLoading={isLoading}
          currentPage={currentPage}
          sortDirection={sortDirection}
          onHeaderCellClick={handleSortChange}
          sortColumns={sortColumns}
          onCellClick={handleCellClick}
        />
      </SoftBox>
    </SoftBox>
  );
};

export default Transactions;
